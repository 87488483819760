import pick from 'lodash/pick';

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchGuests, initiatePrivileged, transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { addTime, findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import {
  getProcess,
  isBookingProcessAlias,
  isEventProcessAlias,
} from '../../transactions/transaction';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_UNCOMING_TIME_SLOTS_REQUEST =
  'app/ListingPage/FETCH_UNCOMING_TIME_SLOTS_REQUEST';
export const FETCH_UNCOMING_TIME_SLOTS_SUCCESS =
  'app/ListingPage/FETCH_UNCOMING_TIME_SLOTS_SUCCESS';
export const FETCH_UNCOMING_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_UNCOMING_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const SEND_COMPLIMENTARY_REQUEST_REQUEST =
  'app/ListingPage/SEND_COMPLIMENTARY_REQUEST_REQUEST';
export const SEND_COMPLIMENTARY_REQUEST_SUCCESS =
  'app/ListingPage/SEND_COMPLIMENTARY_REQUEST_SUCCESS';
export const SEND_COMPLIMENTARY_REQUEST_ERROR = 'app/ListingPage/SEND_COMPLIMENTARY_REQUEST_ERROR';

export const SENT_EVENT_REQUEST = 'app/ListingPage/SENT_EVENT_REQUEST';
export const SENT_EVENT_SUCCESS = 'app/ListingPage/SENT_EVENT_SUCCESS';
export const SENT_EVENT_ERROR = 'app/ListingPage/SENT_EVENT_ERROR';

export const FETCH_TIMESLOT_GUESTS_REQUEST = 'app/ListingPage/FETCH_TIMESLOT_GUESTS_REQUEST';
export const FETCH_TIMESLOT_GUESTS_SUCCESS = 'app/ListingPage/FETCH_TIMESLOT_GUESTS_SUCCESS';
export const FETCH_TIMESLOT_GUESTS_ERROR = 'app/ListingPage/FETCH_TIMESLOT_GUESTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquiryModalOpenForListingId: null,

  sendComplimentaryRequestInProgress: false,
  sendComplimentaryRequestError: null,
  complimentaryRequestModalOpenForListingId: null,

  upcomingTimeSlots: [],
  fetchUpcomingTimeSlotsError: null,
  fetchUpcomingTimeSlotsInProgress: false,

  sentEventInProgress: false,
  sentEventError: null,

  guests: {
    // 'ISO slot start': {
    //   guests: [],
    //   fetchGuestsError: null,
    //   fetchGuestsInProgress: null,
    // },
  },
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };

    case SEND_COMPLIMENTARY_REQUEST_REQUEST:
      return {
        ...state,
        sendComplimentaryRequestInProgress: true,
        sendComplimentaryRequestError: null,
      };
    case SEND_COMPLIMENTARY_REQUEST_SUCCESS:
      return { ...state, sendComplimentaryRequestInProgress: false };
    case SEND_COMPLIMENTARY_REQUEST_ERROR:
      return {
        ...state,
        sendComplimentaryRequestInProgress: false,
        sendComplimentaryRequestError: payload,
      };

    case FETCH_UNCOMING_TIME_SLOTS_REQUEST:
      return {
        ...state,
        fetchUpcomingTimeSlotsInProgress: true,
        fetchUpcomingTimeSlotsError: null,
      };
    case FETCH_UNCOMING_TIME_SLOTS_SUCCESS:
      return {
        ...state,
        upcomingTimeSlots: [...state.upcomingTimeSlots, ...payload],
        fetchUpcomingTimeSlotsInProgress: false,
      };
    case FETCH_UNCOMING_TIME_SLOTS_ERROR:
      return {
        ...state,
        fetchUpcomingTimeSlotsInProgress: false,
        fetchUpcomingTimeSlotsError: payload,
      };

    case SENT_EVENT_REQUEST:
      return {
        ...state,
        sentEventInProgress: true,
        sentEventError: null,
      };
    case SENT_EVENT_SUCCESS:
      return {
        ...state,
        sentEventInProgress: false,
      };
    case SENT_EVENT_ERROR:
      return {
        ...state,
        sentEventInProgress: false,
        sentEventError: payload,
      };

    case FETCH_TIMESLOT_GUESTS_REQUEST: {
      const guests = {
        ...state.guests,
        [payload]: {
          ...state.guests[payload],
          fetchGuestsError: null,
          fetchGuestsInProgress: true,
        },
      };
      return { ...state, guests };
    }

    case FETCH_TIMESLOT_GUESTS_SUCCESS: {
      const slotStart = payload.slotStart;
      const guests = {
        ...state.guests,
        [slotStart]: {
          ...state.guests[slotStart],
          fetchGuestsInProgress: false,
          guests: payload.guests,
        },
      };
      return { ...state, guests };
    }

    case FETCH_TIMESLOT_GUESTS_ERROR: {
      const slotStart = payload.slotStart;
      const guests = {
        ...state.guests,
        [slotStart]: {
          ...state.guests[slotStart],
          fetchGuestsInProgress: false,
          fetchGuestsError: payload.error,
        },
      };
      return { ...state, guests };
    }

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

export const sendComplimentaryRequestRequest = () => ({ type: SEND_COMPLIMENTARY_REQUEST_REQUEST });
export const sendComplimentaryRequestSuccess = () => ({ type: SEND_COMPLIMENTARY_REQUEST_SUCCESS });
export const sendComplimentaryRequestError = e => ({
  type: SEND_COMPLIMENTARY_REQUEST_ERROR,
  error: true,
  payload: e,
});

export const fetchUpcomingTimeSlotsRequest = () => ({ type: FETCH_UNCOMING_TIME_SLOTS_REQUEST });
export const fetchUpcomingTimeSlotsSuccess = timeSlots => ({
  type: FETCH_UNCOMING_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchUpcomingTimeSlotsError = error => ({
  type: FETCH_UNCOMING_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});

export const sentEventRequest = () => ({ type: SENT_EVENT_REQUEST });
export const sentEventSuccess = () => ({ type: SENT_EVENT_SUCCESS });
export const sentEventError = e => ({ type: SENT_EVENT_ERROR, error: true, payload: e });

export const fetchTimeSlotGuestsRequest = slotStart => ({
  type: FETCH_TIMESLOT_GUESTS_REQUEST,
  payload: slotStart,
});
export const fetchTimeSlotGuestsSuccess = (slotStart, guests) => ({
  type: FETCH_TIMESLOT_GUESTS_SUCCESS,
  payload: { slotStart, guests },
});
export const fetchTimeSlotGuestsError = (slotStart, error) => ({
  type: FETCH_TIMESLOT_GUESTS_ERROR,
  error: true,
  payload: { slotStart, error },
});

// ================ Thunks ================ //

export const showListing = (listingId, config, isOwn = false) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(data, sanitizeConfig));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendInquiry = (listing, values) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;
  const { message: messageRaw, influencerPackages, event } = values;
  const params = { listingId };
  if (influencerPackages) {
    params.protectedData = { influencerPackages };
  }

  if (event) {
    params.protectedData = { event };
  }

  const bodyParams = {
    transition: transitions.INQUIRE,
    processAlias,
    params,
  };

  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: messageRaw.trim() }).then(() => {
        dispatch(sendInquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

export const sendComplimentaryRequest = (listing, message, params) => (dispatch, getState, sdk) => {
  dispatch(sendComplimentaryRequestRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendComplimentaryRequestError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;

  const bodyParams = {
    transition: transitions.COMPLIMENTARY_REQUEST,
    processAlias,
    params: {
      listingId,
      protectedData: {
        ...params,
      },
    },
  };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  return initiatePrivileged({ isSpeculative: false, orderData: {}, bodyParams, queryParams })
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendComplimentaryRequestSuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendComplimentaryRequestError(storableError(e)));
      throw e;
    });
};

export const sendEvent = (listing, message, orderParams) => (dispatch, getState, sdk) => {
  dispatch(sentEventRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sentEventError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;

  const { bookingDates, ...otherOrderParams } = orderParams;

  const bookingParamsMaybe = bookingDates || {};

  // Parameters for Marketplace API
  const transitionParams = {
    listingId,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = {
    transition: transitions.INQUIRE,
    processAlias,
    params: transitionParams,
  };

  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sentEventSuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sentEventError(storableError(e)));
      throw e;
    });
};

export const fetchUpcomingTimeSlots = (listingId, start, end, timeZone) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchUpcomingTimeSlotsRequest());

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchUpcomingTimeSlotsSuccess(timeSlots));

      return timeSlots;
    })
    .catch(e => {
      dispatch(fetchUpcomingTimeSlotsError(storableError(e)));
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const unitType = attributes?.publicData?.unitType;
    const timeUnit = unitType === 'hour' ? 'hour' : 'day';
    const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

const fetchAllTimeSlots = async (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;

  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;

    const yearInHours = 365 * 24;
    const ninghtyDaysInHours = 90 * 24;

    const unitType = 'hour';

    const nextBoundary = findNextBoundary(new Date(), unitType, tz);
    const maxFutureDate = addTime(nextBoundary, yearInHours, unitType, tz);

    // Create an array with the maximum number of intervals possible, considering 90 days each.
    const maxIntervals = Array.from({ length: Math.ceil(yearInHours / ninghtyDaysInHours) });

    // Reduce this array to produce intervals (start and end pairs).
    const intervals = maxIntervals.reduce((acc, _, idx) => {
      const start = idx === 0 ? nextBoundary : acc[idx - 1].end;

      let end = addTime(start, ninghtyDaysInHours, unitType, tz);

      if (end > maxFutureDate) {
        end = maxFutureDate;
      }

      acc.push({ start, end });
      return acc;
    }, []);

    // Map over the intervals to generate the dispatch promises.
    const timeSlotsPromises = intervals.map(interval =>
      dispatch(fetchUpcomingTimeSlots(listing.id, interval.start, interval.end, tz))
    );

    const allSlots = await Promise.all(timeSlotsPromises);

    const mergedSlots = allSlots.flat();

    mergedSlots.forEach(slot => {
      const start = new Date(slot.attributes.start).toISOString();
      const users = listing.attributes.publicData.participants?.[start] || [];

      dispatch(fetchTimeSlotGuests(start, users));
    });

    return allSlots;
  }

  return Promise.all([]); // Default case
};

export const fetchTimeSlotGuests = (slotStart, users) => (dispatch, getState, sdk) => {
  const userIds = users.map(user => user.userId);
  const reducedUsers = users.reduce((acc, user) => {
    const { userId, ...rest } = user;
    acc[userId] = rest;
    return acc;
  }, {});

  dispatch(fetchTimeSlotGuestsRequest(slotStart));
  return fetchGuests({ userIds })
    .then(response => {
      const denormalisedUsers = response.map(user => {
        dispatch(addMarketplaceEntities(user));
        const [denormalisedUser] = denormalisedResponseEntities(user);
        denormalisedUser.guestData = reducedUsers[denormalisedUser.id.uuid];
        return denormalisedUser;
      });
      dispatch(fetchTimeSlotGuestsSuccess(slotStart, denormalisedUsers));
    })
    .catch(e => {
      dispatch(fetchTimeSlotGuestsError(slotStart, storableError(e)));
    });
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const loadData = (params, search, config) => dispatch => {
  const listingId = new UUID(params.id);

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, config, true));
  }

  return Promise.all([
    dispatch(showListing(listingId, config)),
    dispatch(fetchReviews(listingId)),
  ]).then(response => {
    const listing = response[0].data.data;
    const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
    if (isBookingProcessAlias(transactionProcessAlias)) {
      // Fetch timeSlots.
      // This can happen parallel to loadData.
      // We are not interested to return them from loadData call.
      fetchMonthlyTimeSlots(dispatch, listing);
    } else if (isEventProcessAlias(transactionProcessAlias)) {
      fetchAllTimeSlots(dispatch, listing);
    }
    return response;
  });
};
