/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

import {
  LISTING_TYPE_EVENT,
  LISTING_TYPE_INFLUENCER,
  LISTING_TYPE_MEDIA_PARTNER,
} from '../util/types';
import { maxLength } from '../util/validators';

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'listingType',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: LISTING_TYPE_EVENT, label: 'Event' },
      { option: LISTING_TYPE_INFLUENCER, label: 'Influencer' },
      { option: LISTING_TYPE_MEDIA_PARTNER, label: 'Media Partner' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Listing Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Listing Type',
      isDetail: false,
    },
  },
  {
    key: 'category',
    scope: 'public',
    includeForListingTypes: [LISTING_TYPE_EVENT],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'festival', label: 'Festival / Music festival' },
      { option: 'conference', label: 'Conference' },
      { option: 'live_gig', label: 'Live Gig' },
      { option: 'tour_dates', label: 'Tour Dates' },
      { option: 'club_night', label: 'Club Night' },
      { option: 'food_drink_event', label: 'Food / Drink Event' },
      { option: 'convention', label: 'Convention' },
      { option: 'fashion_show', label: 'Fashion Show / Runway' },
      { option: 'sports_event', label: 'Sports Event' },
      { option: 'networking_event', label: 'Networking Event / Industry Party' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Type Of Event',
      group: 'primary',
    },
    showConfig: {
      label: 'Type Of Event',
      isDetail: true,
    },
    saveConfig: {
      label: 'Type Of Event',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a type of event.',
    },
  },
  {
    key: 'performers_artists',
    scope: 'public',
    includeForListingTypes: [LISTING_TYPE_EVENT],
    schemaType: 'text',
    showConfig: {
      label: 'Performers / Artists',
      isDetail: true,
    },
    saveConfig: {
      label: 'Performers / Artists (If performers are not confirmed, please write TBC)',
      isRequired: true,
      requiredMessage: 'Performers / Artists field is required.',
    },
  },
  {
    key: 'media_type',
    scope: 'public',
    includeForListingTypes: [LISTING_TYPE_MEDIA_PARTNER],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'publication_magazine', label: 'Publication / Magazine' },
      { option: 'freelance_journalist', label: 'Freelance Journalist / Blogger' },
      { option: 'podcast', label: 'Podcast' },
      { option: 'video_channel', label: 'Video Platform / Channel' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Media Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Media Type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Media Type',
      placeholderMessage: 'Select types...',
      isRequired: false,
    },
  },
  {
    key: 'content_themes',
    scope: 'public',
    includeForListingTypes: [
      LISTING_TYPE_EVENT,
      LISTING_TYPE_INFLUENCER,
      LISTING_TYPE_MEDIA_PARTNER,
    ],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'music', label: 'Music' },
      { option: 'travel', label: 'Travel' },
      { option: 'art', label: 'Art' },
      { option: 'comedy', label: 'Comedy' },
      { option: 'fashion', label: 'Fashion' },
      { option: 'food_drink', label: 'Food / Drink' },
      { option: 'nsfw', label: 'NSFW' },
      { option: 'tattoo_body_art', label: 'Tattoo / Body Art' },
      { option: 'technology', label: 'Technology' },
      { option: 'coaching_advice', label: 'Coaching / Advice' },
      { option: 'health_fitness', label: 'Health / Fitness' },
      { option: 'cosplay_fancy_dress', label: 'Cosplay / Fancy Dress' },
      { option: 'nature', label: 'Nature' },
      { option: 'other', label: 'Other' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Content Themes',
      group: 'primary',
    },
    showConfig: {
      label: 'Content Themes',
      isDetail: true,
    },
    saveConfig: {
      label: 'Content Themes',
      placeholderMessage: 'Select themes...',
      isRequired: true,
      requiredMessage: 'You need to select a theme.',
    },
  },
  {
    key: 'music_genres',
    scope: 'public',
    includeForListingTypes: [
      LISTING_TYPE_EVENT,
      LISTING_TYPE_INFLUENCER,
      LISTING_TYPE_MEDIA_PARTNER,
    ],
    schemaType: 'multi-enum',
    searchMode: 'has_any',
    enumOptions: [
      { option: 'pop', label: 'Pop Music' },
      { option: 'metal', label: 'Metal / Heavy Metal' },
      { option: 'electronic', label: 'Electronic Music' },
      { option: 'indie', label: 'Indie Music' },
      { option: 'rock', label: 'Rock' },
      { option: 'house', label: 'House' },
      { option: 'techno', label: 'Techno' },
      { option: 'bass', label: 'Bass Music' },
      { option: 'reggae', label: 'Reggae / Ska' },
      { option: 'rap', label: 'Rap' },
      { option: 'trap', label: 'Trap' },
      { option: 'hyperpop', label: 'Hyperpop' },
      { option: 'rnb', label: 'RnB' },
      { option: 'soul', label: 'Soul' },
      { option: 'hiphop', label: 'Hip Hop / LoFi Hip Hop' },
      { option: 'grime', label: 'Grime' },
      { option: 'edm', label: 'EDM' },
      { option: 'folk', label: 'Folk' },
      { option: 'singer_songwriter', label: 'Singer-Songwriter' },
      { option: 'country', label: 'Country / Americana' },
      { option: 'experimental', label: 'Experimental' },
      { option: 'alternative', label: 'Alternative' },
      { option: 'world', label: 'World / Global' },
      { option: 'afrobeats', label: 'Afrobeats' },
      { option: 'disco', label: 'Disco / Disco House' },
      { option: 'uk_garage', label: 'UK Garage' },
      { option: 'jazz', label: 'Jazz' },
      { option: 'classical', label: 'Classical / Orchestral' },
      { option: 'funk', label: 'Funk' },
      { option: 'neo_classical', label: 'Neo Classical' },
      { option: 'alternative_rnb', label: 'Alternative RnB' },
      { option: 'jungle', label: 'Jungle' },
      { option: 'downtempo', label: 'Downtempo' },
      { option: 'cheese', label: 'Cheese / Retro' },
      { option: 'trance', label: 'Trance' },
      { option: 'gabba', label: 'Gabba / Hardcore' },
      { option: 'soft_rock', label: 'Soft Rock' },
      { option: 'classic_rock', label: 'Classic Rock' },
      { option: 'grunge', label: 'Grunge' },
      { option: 'psychedelic_rock', label: 'Psychedelic Rock' },
      { option: 'amapiano', label: 'Amapiano' },
      { option: 'dubstep', label: 'Dubstep' },
      { option: 'drum_and_bass', label: 'Drum & Bass' },
      { option: 'ambient', label: 'Ambient' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Music Genres',
      group: 'primary',
    },
    showConfig: {
      label: 'Music Genres',
      isDetail: true,
    },
    saveConfig: {
      label: 'Music Genres',
      placeholderMessage: 'Select genres...',
      isRequired: false,
    },
    listingTypeConfig: {
      [LISTING_TYPE_EVENT]: {
        extraOptions: [{ option: 'non_applicable', label: 'Non Applicable / Not a Music Event' }],
      },
      [LISTING_TYPE_INFLUENCER]: {
        extraOptions: [{ option: 'open_to_anything', label: "I'm Open to anything" }],
      },
      [LISTING_TYPE_MEDIA_PARTNER]: {
        extraOptions: [{ option: 'open_to_anything', label: "I'm Open to anything" }],
      },
    },
  },
  {
    key: 'expect_to_see',
    scope: 'public',
    includeForListingTypes: [LISTING_TYPE_EVENT],
    schemaType: 'text',
    showConfig: {
      label: 'Expect to See',
      isDetail: true,
    },
    saveConfig: {
      label: 'Expect to See',
      isRequired: false,
    },
  },
  {
    key: 'requirements',
    scope: 'public',
    includeForListingTypes: [LISTING_TYPE_MEDIA_PARTNER],
    schemaType: 'text',
    showConfig: {
      label: 'Requirements',
      isDetail: true,
    },
    saveConfig: {
      label: 'Requirements',
      placeholderMessage: 'Describe any requirements you need to attend or cover an event',
      isRequired: false,
    },
  },
  {
    key: 'publication_list',
    scope: 'public',
    includeForListingTypes: [LISTING_TYPE_MEDIA_PARTNER],
    schemaType: 'text',
    showConfig: {
      label: 'Publication List',
      isDetail: true,
    },
    saveConfig: {
      label: 'Please list the publication(s) you write for',
      placeholderMessage: 'Add your list...',
      isRequired: false,
    },
  },
  {
    key: 'photopass_requirements',
    scope: 'public',
    includeForListingTypes: [LISTING_TYPE_EVENT, LISTING_TYPE_MEDIA_PARTNER],
    schemaType: 'enum',
    enumOptions: [
      { option: 'yes', label: 'Yes' },
      { option: 'no', label: 'No' },
      { option: 'not_sure', label: 'TBC' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Are you able to provide a photopass?',
      group: 'secondary',
    },
    showConfig: {
      label: 'Are you able to provide a photopass?',
      isDetail: true,
    },
    saveConfig: {
      label: 'Are you able to provide a photopass?',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },
  {
    key: 'top_audience_countries',
    scope: 'public',
    includeForListingTypes: [LISTING_TYPE_INFLUENCER, LISTING_TYPE_MEDIA_PARTNER],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'dz', label: 'Algeria' },
      { option: 'ar', label: 'Argentina' },
      { option: 'am', label: 'Armenia' },
      { option: 'at', label: 'Austria' },
      { option: 'au', label: 'Australia' },
      { option: 'be', label: 'Belgium' },
      { option: 'br', label: 'Brazil' },
      { option: 'bg', label: 'Bulgaria' },
      { option: 'ca', label: 'Canada' },
      { option: 'cl', label: 'Chile' },
      { option: 'co', label: 'Colombia' },
      { option: 'hr', label: 'Croatia' },
      { option: 'dk', label: 'Denmark' },
      { option: 'ec', label: 'Ecuador' },
      { option: 'sv', label: 'El Salvador' },
      { option: 'fi', label: 'Finland' },
      { option: 'fr', label: 'France' },
      { option: 'de', label: 'Germany' },
      { option: 'gh', label: 'Ghana' },
      { option: 'gr', label: 'Greece' },
      { option: 'gt', label: 'Guatemala' },
      { option: 'in', label: 'India' },
      { option: 'id', label: 'Indonesia' },
      { option: 'ie', label: 'Ireland' },
      { option: 'it', label: 'Italy' },
      { option: 'jp', label: 'Japan' },
      { option: 'ke', label: 'Kenya' },
      { option: 'lt', label: 'Lithuania' },
      { option: 'mr', label: 'Mauritania' },
      { option: 'ml', label: 'Mali' },
      { option: 'mx', label: 'Mexico' },
      { option: 'ma', label: 'Morroco' },
      { option: 'nz', label: 'New Zealand' },
      { option: 'nl', label: 'Netherlands' },
      { option: 'ng', label: 'Nigeria' },
      { option: 'no', label: 'Norway' },
      { option: 'pk', label: 'Pakistan' },
      { option: 'pe', label: 'Peru' },
      { option: 'pt', label: 'Portugal' },
      { option: 'ru', label: 'Russia' },
      { option: 'sg', label: 'Singapore' },
      { option: 'sk', label: 'Slovakia' },
      { option: 'za', label: 'South Africa' },
      { option: 'es', label: 'Spain' },
      { option: 'se', label: 'Sweden' },
      { option: 'ch', label: 'Switzerland' },
      { option: 'tw', label: 'Taiwan' },
      { option: 'tn', label: 'Tunisia' },
      { option: 'tr', label: 'Turkey' },
      { option: 'ae', label: 'UAE' },
      { option: 'us', label: 'United States' },
      { option: 've', label: 'Venezuela' },
      { option: 'vn', label: 'Vietnam' },
      { option: 'gb', label: 'United Kingdom' },
      { option: 'unknown', label: "I Don't Know" },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Top Audience Demographics (Readership or Social Media Following)',
      group: 'primary',
    },
    showConfig: {
      label: 'Top Audience Demographics (Readership or Social Media Following)',
      isDetail: true,
    },
    saveConfig: {
      label: 'Top Audience Demographics (Readership or Social Media Following)',
      placeholderMessage: 'Select countries...',
      isRequired: true,
    },
    listingTypeConfig: {
      [LISTING_TYPE_INFLUENCER]: {
        max: 3,
        validate: maxLength('3 options max', 3),
      },
      [LISTING_TYPE_MEDIA_PARTNER]: {
        max: 3,
        validate: maxLength('3 options max', 3),
      },
    },
  },
  {
    key: 'audience_description',
    scope: 'public',
    includeForListingTypes: [LISTING_TYPE_MEDIA_PARTNER],
    schemaType: 'text',
    showConfig: {
      label: 'Audience Description',
      isDetail: true,
    },
    saveConfig: {
      label: 'Any further info on your audience',
      placeholderMessage: 'Tell us about your audience...',
      isRequired: false,
    },
  },
  {
    key: 'main_platform',
    scope: 'public',
    includeForListingTypes: [LISTING_TYPE_INFLUENCER, LISTING_TYPE_MEDIA_PARTNER],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'facebook', label: 'Facebook' },
      { option: 'instagram', label: 'Instagram' },
      { option: 'twitter', label: 'Twitter' },
      { option: 'youtube', label: 'YouTube' },
      { option: 'twitch', label: 'Twitch' },
      { option: 'onlyfans', label: 'Only Fans' },
      { option: 'patreon', label: 'Patreon' },
      { option: 'tiktok', label: 'TikTok' },
      { option: 'website', label: 'Website' },
      { option: 'other', label: 'Other' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Main Platforms',
      group: 'primary',
    },
    showConfig: {
      label: 'Main Platforms',
      isDetail: false,
    },
    saveConfig: {
      label: 'Main Platforms',
      placeholderMessage: 'Select platforms...',
      isRequired: true,
      requiredMessage: 'You need to select a main platform.',
    },
  },
  {
    key: 'social_media_links',
    scope: 'public',
    includeForListingTypes: [LISTING_TYPE_INFLUENCER, LISTING_TYPE_MEDIA_PARTNER],
    schemaType: 'custom_social_media_links',
    enumOptions: [
      { option: 'facebook', label: 'Facebook' },
      { option: 'instagram', label: 'Instagram' },
      { option: 'twitter', label: 'Twitter' },
      { option: 'youtube', label: 'YouTube' },
      { option: 'twitch', label: 'Twitch' },
      { option: 'onlyfans', label: 'Only Fans' },
      { option: 'patreon', label: 'Patreon' },
      { option: 'tiktok', label: 'TikTok' },
      { option: 'website', label: 'Website' },
      { option: 'other', label: 'Other' },
    ],
    showConfig: {
      label: 'Main Platforms',
      isDetail: true,
    },
    saveConfig: {
      label: 'Social Media Links',
      isRequired: true,
      requiredMessage: 'Required.',
      urlLabel: 'Url',
      urlPlaceholderMessage: 'Enter url...',
      followersCountLabel: 'Number of Followers',
      followersCountPlaceholderMessage: 'Enter number of followers...',
    },
  },
  {
    key: 'average_cost_per_post',
    scope: 'public',
    includeForListingTypes: [LISTING_TYPE_INFLUENCER],
    schemaType: 'enum',
    enumOptions: [
      { option: '10-30', label: '£10-30' },
      { option: '30-50', label: '£30-50' },
      { option: '50-100', label: '£50-100' },
      { option: '100-150', label: '£100-150' },
      { option: '150-200', label: '£150-200' },
      { option: '200-300', label: '£200-300' },
      { option: '300+', label: '£300+' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Average Cost Per Post',
      group: 'primary',
    },
    showConfig: {
      label: 'Average Cost Per Post',
      isDetail: true,
    },
    saveConfig: {
      label: 'Average Cost Per Post',
      placeholderMessage: 'Select an option...',
      isRequired: true,
      requiredMessage: 'You need to select a price range.',
    },
  },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  {
    listingType: LISTING_TYPE_EVENT,
    label: 'Event',
    transactionType: {
      process: 'event-booking',
      alias: 'event-booking/release-1',
      unitType: 'event-booking',
    },
  },
  {
    listingType: LISTING_TYPE_INFLUENCER,
    label: 'Influencer',
    transactionType: {
      process: 'influencer-service',
      alias: 'influencer-service/release-1',
      unitType: 'influencer-service',
    },
    stockType: 'oneItem',
  },
  {
    listingType: LISTING_TYPE_MEDIA_PARTNER,
    label: 'Media partner',
    transactionType: {
      process: 'media-partner-connection',
      alias: 'media-partner-connection/release-1',
      unitType: 'media-partner-connection-fee',
    },
    stockType: 'oneItem',
  },

  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
