import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import pick from 'lodash/pick';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser, normalizeUnitTypeForTranslation } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import {
  isBookingProcessAlias,
  isEventProcessAlias,
  isInfluencerProcessAlias,
  isMediaPartnerProcessAlias,
} from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    const priceTitle = price.amount === 0 ? 'Free' : formattedPrice;
    return { formattedPrice: priceTitle, priceTitle };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const ExtendedDataInfo = ({ publicData, config }) => {
  const listingConfig = config.listing;

  const isEvent = isEventProcessAlias(publicData?.transactionProcessAlias);
  const isInfluencer = isInfluencerProcessAlias(publicData?.transactionProcessAlias);
  const isMediaPartner = isMediaPartnerProcessAlias(publicData?.transactionProcessAlias);

  // For event from publicData pick only event specific data: location, performers_artists, category;
  // For influencer from publicData pick only influencer specific data: top_audience_countries, average_cost_per_post, content_themes;
  // For media partner from publicData pick only media partner specific data: location, content_themes;

  let filteredData = {};

  if (isEvent) {
    filteredData = pick(publicData, ['location', 'performers_artists', 'category']);
  } else if (isInfluencer) {
    filteredData = pick(publicData, [
      'top_audience_countries',
      'average_cost_per_post',
      'content_themes',
    ]);
  } else if (isMediaPartner) {
    filteredData = pick(publicData, ['location', 'content_themes']);
  }

  const content = Object.keys(filteredData).reduce((pickedElements, key) => {
    const value = filteredData[key];
    const hasValue = value !== null;
    const label = (
      <span className={css.extendedDataInfoItemLabel}>
        <FormattedMessage id={`ListingCard.extended-data.${key}`} />
      </span>
    );

    if (key === 'location' && hasValue) {
      return [
        ...pickedElements,
        <div
          key={key}
          className={classNames(css.extendedDataInfoItem, css.extendedDataInfoLocation)}
        >
          {value.address}
        </div>,
      ];
    }

    const extendedFieldConfig = listingConfig?.listingFields?.find(f => f.key === key);

    const { enumOptions } = extendedFieldConfig || {};

    if (Array.isArray(value) && enumOptions && label) {
      const allOptions = createFilterOptions(enumOptions);
      const selectedOptions = value.map(v => allOptions.find(o => v === `${o.key}`));
      const selectedLabels = selectedOptions.map(o => o?.label);

      return [
        ...pickedElements,
        <div key={key} className={css.extendedDataInfoItem}>
          {label} {selectedLabels.join(', ')}
        </div>,
      ];
    }

    if (typeof value === 'string' && label) {
      const resultValue = enumOptions?.find(o => o.option === value)?.label || value;

      // split string by comma, new line or semicolon, filter out empty strings and trim spaces
      const resultValueArray = resultValue
        .split(/[,;\n]+/)
        .filter(Boolean)
        .map(s => s.trim());

      // join array with comma and space and truncate to 100 characters
      const resultValueString = resultValueArray.join(', ');

      // truncate resultValueString to 100 characters and add ellipsis if truncated
      const resultValueStringTruncated =
        resultValueString.length > 50
          ? `${resultValueString.substring(0, 50)}...`
          : resultValueString;

      return [
        ...pickedElements,
        <div key={key} className={css.extendedDataInfoItem}>
          {label} {resultValueStringTruncated}
        </div>,
      ];
    }

    return pickedElements;
  }, []);

  return <div className={css.extendedDataInfo}>{content}</div>;
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);

  const isEvent = isEventProcessAlias(publicData?.transactionProcessAlias);
  const isInfluencer = isInfluencerProcessAlias(publicData?.transactionProcessAlias);
  const isMediaPartner = isMediaPartnerProcessAlias(publicData?.transactionProcessAlias);

  const hidePrice = isEvent || isInfluencer;

  const showUnit = isBookable || isMediaPartner;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.mainInfo}>
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
        {showAuthorInfo ? (
          <div className={css.authorInfo}>
            <FormattedMessage id="ListingCard.author" values={{ authorName }} />
          </div>
        ) : null}
      </div>
      {hidePrice ? null : (
        <div className={css.info}>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            {showUnit ? (
              <div className={css.perUnit}>
                <FormattedMessage
                  id="ListingCard.perUnit"
                  values={{ unitType: normalizeUnitTypeForTranslation(publicData?.unitType) }}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
      <ExtendedDataInfo publicData={publicData} config={config} />
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: false,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
