/*
 * Renders a set of options with selected and non-selected values.
 *
 * The corresponding component when selecting the values is
 * FieldCheckboxGroup.
 *
 */

import React from 'react';
import PropTypes, { object } from 'prop-types';
import classNames from 'classnames';

import css from './PropertyGroup.module.css';
import ExternalLink from '../ExternalLink/ExternalLink';
import { FormattedMessage } from 'react-intl';

const checkSelected = (options, selectedOptions) => {
  return options.map(option => ({
    key: option.key,
    label: option.label,
    isSelected: !!selectedOptions?.[option.key], //includes(selectedOptions, option.key),
  }));
};

const IconCheck = props => {
  const isVisible = props.isVisible;
  const classes = isVisible ? css.checkIcon : classNames(css.checkIcon, css.hidden);

  return (
    <svg width="9" height="9" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path
        className={css.marketplaceFill}
        d="M2.636621 7.7824771L.3573694 5.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254783L6.1721653.505847C6.565577-.0373166 7.326743-.1636902 7.8777637.227582c.5473554.3912721.6731983 1.150729.2797866 1.6951076L4.4924979 7.631801c-.2199195.306213-.5803433.5067096-.9920816.5067096-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
        fillRule="evenodd"
      />
    </svg>
  );
};

const Item = props => {
  const { label, isSelected, url, followersCount } = props;
  const labelClass = isSelected ? css.selectedLabel : css.notSelectedLabel;
  return (
    <li className={css.item}>
      <span className={css.iconWrapper}>
        <IconCheck isVisible={isSelected} />
      </span>
      {isSelected && !!url ? (
        <div className={css.labelWrapper}>
          <ExternalLink href={url} className={labelClass}>
            {label}
          </ExternalLink>
          {'  '}
          {followersCount && (
            <span className={css.followersCount}>
              <FormattedMessage
                id="SocialLinksPropertyGroup.followers"
                values={{
                  count: followersCount,
                }}
              />
            </span>
          )}
        </div>
      ) : (
        <div className={css.labelWrapper}>
          <span className={labelClass}>{label}</span>
        </div>
      )}
    </li>
  );
};

const SocialLinksPropertyGroup = props => {
  const { rootClassName, className, id, options, selectedOptions, twoColumns } = props;
  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(classes, css.twoColumns) : classes;

  const checked = checkSelected(options, selectedOptions);

  return (
    <ul className={listClasses}>
      {checked.map(option => {
        if (!option.isSelected) {
          return null;
        }

        return (
          <Item
            key={`${id}.${option.key}`}
            label={option.label}
            isSelected={option.isSelected}
            url={selectedOptions?.[option.key]?.url}
            followersCount={selectedOptions?.[option.key]?.followers_count}
          />
        );
      })}
    </ul>
  );
};

SocialLinksPropertyGroup.defaultProps = {
  rootClassName: null,
  className: null,
  selectedOptions: [],
  twoColumns: false,
};

const { arrayOf, bool, node, shape, string } = PropTypes;

SocialLinksPropertyGroup.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ),
  selectedOptions: object.isRequired,
  twoColumns: bool,
};

export default SocialLinksPropertyGroup;
