import React, { useRef } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import SignatureCanvas from 'react-signature-canvas';
import { FormattedMessage } from '../../util/reactIntl';
import { PrimaryButton, ValidationError } from '../../components';

import css from './FieldSignature.module.css';

const FieldSignatureComponent = props => {
  const inputRef = useRef(null);
  const { className, rootClassName, id, input, label, meta, disabled, ...rest } = props;
  const classes = classNames(rootClassName || css.root, className);

  const clearCanvas = e => {
    e.preventDefault();
    inputRef.current.clear();
    input.onChange(null);
  };

  const saveCanvas = () => {
    input.onChange(inputRef.current.getTrimmedCanvas().toDataURL('image/png'));
    input.onBlur();
  };

  return (
    <div className={classes}>
      <label className={css.label} htmlFor={id}>
        {label}
      </label>
      <div className={css.signatureContainer}>
        <PrimaryButton type="button" className={css.clearCanvasBotton} onClick={clearCanvas}>
          <FormattedMessage id="FieldSignature.clearCanvas" />
        </PrimaryButton>
        {disabled && <div className={css.signatureCanvasDisabledMask} />}
        <SignatureCanvas
          ref={inputRef}
          canvasProps={{ height: 150, width: 500, className: css.signatureCanvas }}
          onEnd={saveCanvas}
          {...rest}
        />
      </div>
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

const FieldSignature = props => {
  return <Field component={FieldSignatureComponent} {...props} />;
};

export default FieldSignature;
