import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useSubscription = () => {
  const { ownEventListingsQuantity } = useSelector(state => state.user);
  const { subscription } = useSelector(state => state.stripeSubscriptions);

  const eventListingsLimit =
    subscription?.items?.data[0]?.price?.metadata?.allowed_listing_quantity ?? 1;
  const TERMINAL_STATES = useMemo(() => ['canceled', 'past_due', 'unpaid'], []);

  const hasSubscription = !!subscription?.id || eventListingsLimit > 0;
  const isActive = subscription?.status === 'active' || eventListingsLimit > 0;
  const isTerminalState = useMemo(() => TERMINAL_STATES.includes(subscription?.status), [
    subscription?.status,
    TERMINAL_STATES,
  ]);

  const canPublishListings = useMemo(() => {
    if (isTerminalState) {
      // Terminal states can't publish listings
      return false;
    }

    // If no event listing limit is specified, users are not allowed to publish
    if (!eventListingsLimit) {
      return false;
    }

    // If the event listing limit is 'unlimited', allow publishing
    if (eventListingsLimit === 'unlimited') {
      return true;
    }

    if (isNaN(eventListingsLimit)) {
      return false;
    }

    // Otherwise, allow publishing if the current number of listings is less than the limit
    return ownEventListingsQuantity < eventListingsLimit;
  }, [isTerminalState, eventListingsLimit, ownEventListingsQuantity]);

  const isAllowedListingQuantityExceeded = useMemo(() => {
    if (isActive && !canPublishListings) {
      return true;
    }

    return false;
  }, [canPublishListings, isActive]);

  return {
    subscription,
    hasSubscription,
    isActive,
    isTrial: subscription?.status === 'trialing',
    isCanceled: subscription?.status === 'canceled',
    isPastDue: subscription?.status === 'past_due',
    canPublishListings,
    isAllowedListingQuantityExceeded,
  };
};

export const withSubscription = Component => {
  return props => {
    const sub = useSubscription();

    return <Component userSubscription={sub} {...props} />;
  };
};
