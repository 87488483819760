import { fetchCurrentUser } from '../../ducks/user.duck';
import { setInitialValues as setInitialValuesForPaymentMethods } from '../../ducks/paymentMethods.duck';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';

import { stripeSubscriptionsApi } from '../../util/api';

// ================ Action types ================ //

export const STRIPE_CUSTOMER_REQUEST = 'app/SubscriptionsPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/SubscriptionsPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/SubscriptionsPage/STRIPE_CUSTOMER_ERROR';

export const FETCH_PRICES_REQUEST = 'app/SubscriptionsPage/FETCH_PRICES_REQUEST';
export const FETCH_PRICES_SUCCESS = 'app/SubscriptionsPage/FETCH_PRICES_SUCCESS';
export const FETCH_PRICES_ERROR = 'app/SubscriptionsPage/FETCH_PRICES_ERROR';

// ================ Reducer ================ //

const initialState = {
  stripeCustomerFetched: false,
  fetchPricesInProgress: false,
  fetchPricesError: null,
  prices: [],
};

export default function subscriptionsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case FETCH_PRICES_REQUEST:
      return {
        ...state,
        fetchPricesInProgress: true,
        fetchPricesError: null,
      };
    case FETCH_PRICES_SUCCESS:
      return {
        ...state,
        fetchPricesInProgress: false,
        prices: payload,
      };
    case FETCH_PRICES_ERROR:
      return {
        ...state,
        fetchPricesInProgress: false,
        fetchPricesError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const fetchPricesRequest = () => ({ type: FETCH_PRICES_REQUEST });
export const fetchPricesSuccess = prices => ({
  type: FETCH_PRICES_SUCCESS,
  payload: prices,
});
export const fetchPricesError = e => ({
  type: FETCH_PRICES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchStripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(() => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'fetch-stripe-customer-failed');
      dispatch(stripeCustomerError(error));
    });
};

export const fetchPrices = () => (dispatch, getState, sdk) => {
  dispatch(fetchPricesRequest());

  return stripeSubscriptionsApi
    .config()
    .then(response => {
      const prices = response.prices;
      dispatch(fetchPricesSuccess(prices));
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'fetch-prices-failed');
      dispatch(fetchPricesError(error));
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  dispatch(setInitialValuesForPaymentMethods());

  return Promise.all([dispatch(fetchStripeCustomer()), dispatch(fetchPrices())]);
};
