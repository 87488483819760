import { stripeSubscriptionsApi } from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';

// ================ Action types ================ //

export const SET_SUBSCRIPTION = 'app/stripeSubscriptions/SET_SUBSCRIPTION';

export const CREATE_CHECKOUT_SESSION_REQUEST =
  'app/stripeSubscriptionsApi/CREATE_CHECKOUT_SESSION_REQUEST';
export const CREATE_CHECKOUT_SESSION_SUCCESS =
  'app/stripeSubscriptionsApi/CREATE_CHECKOUT_SESSION_SUCCESS';
export const CREATE_CHECKOUT_SESSION_ERROR =
  'app/stripeSubscriptionsApi/CREATE_CHECKOUT_SESSION_ERROR';

export const CREATE_PORTAL_SESSION_REQUEST =
  'app/stripeSubscriptionsApi/CREATE_PORTAL_SESSION_REQUEST';
export const CREATE_PORTAL_SESSION_SUCCESS =
  'app/stripeSubscriptionsApi/CREATE_PORTAL_SESSION_SUCCESS';
export const CREATE_PORTAL_SESSION_ERROR = 'app/stripeSubscriptionsApi/CREATE_PORTAL_SESSION_ERROR';

export const UPDATE_SUBSCRIPTION_REQUEST = 'app/stripeSubscriptionsApi/UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'app/stripeSubscriptionsApi/UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_ERROR = 'app/stripeSubscriptionsApi/UPDATE_SUBSCRIPTION_ERROR';

export const FETCH_STRIPE_CUSTOMER_REQUEST =
  'app/stripeSubscriptionsApi/FETCH_STRIPE_CUSTOMER_REQUEST';
export const FETCH_STRIPE_CUSTOMER_SUCCESS =
  'app/stripeSubscriptionsApi/FETCH_STRIPE_CUSTOMER_SUCCESS';
export const FETCH_STRIPE_CUSTOMER_ERROR = 'app/stripeSubscriptionsApi/FETCH_STRIPE_CUSTOMER_ERROR';

// ================ Reducer ================ //

const initialState = {
  subscription: null,
  createCheckoutSessionInProgress: false,
  createCheckoutSessionError: null,
  checkoutSession: null,
  createPortalSessionInProgress: false,
  createPortalSessionError: null,
  portalSession: null,
  updateSubscriptionInProgress: null,
  updateSubscriptionError: null,
  fetchStripeCustomerInProgress: false,
  fetchStripeCustomerError: null,
  stripeCustomer: null,
};

export default function stripeSupscriptionsReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_SUBSCRIPTION:
      return { ...state, subscription: payload };

    case CREATE_CHECKOUT_SESSION_REQUEST:
      return {
        ...state,
        createCheckoutSessionInProgress: true,
        createCheckoutSessionError: null,
      };
    case CREATE_CHECKOUT_SESSION_SUCCESS:
      return {
        ...state,
        createCheckoutSessionInProgress: false,
        checkoutSession: payload,
      };
    case CREATE_CHECKOUT_SESSION_ERROR:
      return {
        ...state,
        createCheckoutSessionInProgress: false,
        createCheckoutSessionError: payload,
      };

    case CREATE_PORTAL_SESSION_REQUEST:
      return {
        ...state,
        createPortalSessionInProgress: true,
        createPortalSessionError: null,
      };
    case CREATE_PORTAL_SESSION_SUCCESS:
      return {
        ...state,
        createPortalSessionInProgress: false,
        portalSession: payload,
      };
    case CREATE_PORTAL_SESSION_ERROR:
      return {
        ...state,
        createPortalSessionInProgress: false,
        createPortalSessionError: payload,
      };

    case UPDATE_SUBSCRIPTION_REQUEST:
      return { ...state, updateSubscriptionError: null, updateSubscriptionInProgress: true };
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        updateSubscriptionInProgress: false,
        subscription: payload,
      };
    case UPDATE_SUBSCRIPTION_ERROR:
      console.error(payload);
      return {
        ...state,
        updateSubscriptionError: payload,
        updateSubscriptionInProgress: false,
      };

    case FETCH_STRIPE_CUSTOMER_REQUEST:
      return {
        ...state,
        fetchStripeCustomerInProgress: true,
        fetchStripeCustomerError: null,
      };
    case FETCH_STRIPE_CUSTOMER_SUCCESS:
      return {
        ...state,
        fetchStripeCustomerInProgress: false,
        stripeCustomer: payload,
      };
    case FETCH_STRIPE_CUSTOMER_ERROR:
      return {
        ...state,
        fetchStripeCustomerInProgress: false,
        fetchStripeCustomerError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setSubscription = subscription => ({
  type: SET_SUBSCRIPTION,
  payload: subscription,
});

export const updateSubscriptionRequest = () => ({ type: UPDATE_SUBSCRIPTION_REQUEST });
export const updateSubscriptionSuccess = subscription => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
  payload: subscription,
});
export const updateSubscriptionError = error => ({
  type: UPDATE_SUBSCRIPTION_ERROR,
  error: true,
  payload: storableError(error),
});

export const fetchStripeCustomerRequest = () => ({ type: FETCH_STRIPE_CUSTOMER_REQUEST });
export const fetchStripeCustomerSuccess = stripeCustomer => ({
  type: FETCH_STRIPE_CUSTOMER_SUCCESS,
  payload: stripeCustomer,
});
export const fetchStripeCustomerError = error => ({
  type: FETCH_STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: storableError(error),
});

export const createCheckoutSessionRequest = () => ({ type: CREATE_CHECKOUT_SESSION_REQUEST });
export const createCheckoutSessionSuccess = checkoutSession => ({
  type: CREATE_CHECKOUT_SESSION_SUCCESS,
  payload: checkoutSession,
});
export const createCheckoutSessionError = error => ({
  type: CREATE_CHECKOUT_SESSION_ERROR,
  error: true,
  payload: storableError(error),
});

export const createPortalSessionRequest = () => ({ type: CREATE_PORTAL_SESSION_REQUEST });
export const createPortalSessionSuccess = portalSession => ({
  type: CREATE_PORTAL_SESSION_SUCCESS,
  payload: portalSession,
});
export const createPortalSessionError = error => ({
  type: CREATE_PORTAL_SESSION_ERROR,
  error: true,
  payload: storableError(error),
});

// ================ Thunks ================ //

export const createCheckoutSession = ({ priceId, successURL, cancelURL }) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(createCheckoutSessionRequest());

  return stripeSubscriptionsApi
    .createCheckoutSession({ priceId, successURL, cancelURL })
    .then(session => {
      dispatch(createCheckoutSessionSuccess(session));
      return session;
    })
    .catch(e => {
      dispatch(createCheckoutSessionError(e));
      log.error(e, 'create-checkout-session-failed');
      throw e;
    });
};

export const createPortalSession = ({ returnUrl }) => async (dispatch, getState, sdk) => {
  dispatch(createPortalSessionRequest());

  return stripeSubscriptionsApi
    .createPortalSession({ returnUrl })
    .then(session => {
      dispatch(createPortalSessionSuccess(session));
      return session;
    })
    .catch(e => {
      dispatch(createPortalSessionError(e));
      log.error(e, 'create-portal-session-failed');
      throw e;
    });
};

export const updateExistingSubscription = ({ subscriptionId, priceId }) => async dispatch => {
  dispatch(updateSubscriptionRequest());

  return stripeSubscriptionsApi
    .updateSubscription({ subscriptionId, priceId })
    .then(subscription => {
      dispatch(updateSubscriptionSuccess(subscription));
      return subscription;
    })
    .catch(e => {
      dispatch(updateSubscriptionError(e));
      log.error(e, 'update-subscription-failed', { subscriptionId, priceId });
      throw e;
    });
};

export const fetchStripeCustomer = () => async (dispatch, getState, sdk) => {
  dispatch(fetchStripeCustomerRequest());

  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchStripeCustomerSuccess(null));
    return Promise.resolve(null);
  }

  return stripeSubscriptionsApi
    .retrieveStripeCustomer()
    .then(customer => {
      dispatch(fetchStripeCustomerSuccess(customer));
      if (customer.subscriptions?.data?.length > 0) {
        dispatch(setSubscription(customer.subscriptions.data[0]));
      }

      return customer;
    })
    .catch(e => {
      dispatch(fetchStripeCustomerError(e));
      log.error(e, 'fetch-stripe-customer-failed');
      throw e;
    });
};
